<template>
  <div id="sec-one-left">
    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="question-block">
      <div class="question-text">
        Нужно ли Вам реагирование сотрудниками охраны на сработки датчиков?
        <span
          class="info"
          @click="
            text = GETTEXT[1];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid buttons-grid-two">
        <div
          class="button"
          :class="{ 'active-select-button': leftOne.securityReaction == false }"
          @click="
            leftOne.securityReaction = false;
            setChanges();
          "
        >
          НЕТ
        </div>
        <div
          class="button button-right"
          :class="{ 'active-select-button': leftOne.securityReaction == true }"
          @click="
            leftOne.securityReaction = true;
            setChanges();
          "
        >
          ДА
        </div>
      </div>
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="question-block">
      <div class="question-text">
        Какой комплект Вам нужен?
        <span
          class="info"
          @click="
            text = GETTEXT[2];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid buttons-grid-no-grid">
        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityType == 'secureSignal'
          }"
          @click="
            leftOne.securityType = 'secureSignal';
            setChanges();
          "
        >
          ОХРАННАЯ СИГНАЛИЗАЦИЯ
        </div>
        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityType == 'secureButton'
          }"
          @click="
            leftOne.securityType = 'secureButton';
            setChanges();
          "
        >
          ТРЕВОЖНАЯ КНОПКА
        </div>

        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityType == 'secureComplex'
          }"
          @click="
            leftOne.securityType = 'secureComplex';
            setChanges();
          "
        >
          КОМПЛЕКСНАЯ ОХРАНА
        </div>
      </div>
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div
      class="question-block"
      v-if="
        leftOne.securityType == 'secureSignal' ||
          leftOne.securityType == 'secureComplex'
      "
    >
      <div class="question-text">
        Сколько у Вас входов на объект?
        <span
          class="info"
          @click="
            text = GETTEXT[3];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid">
        <div
          class="button"
          @click="
            leftOne.entriesQuantity = 1;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.entriesQuantity == 1 }"
        >
          1
        </div>
        <div
          class="button"
          @click="
            leftOne.entriesQuantity = 2;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.entriesQuantity == 2 }"
        >
          2
        </div>
        <div
          class="button"
          @click="
            leftOne.entriesQuantity = 3;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.entriesQuantity == 3 }"
        >
          3
        </div>
        <div
          class="button"
          @click="
            leftOne.entriesQuantity = 4;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.entriesQuantity == 4 }"
        >
          4
        </div>
      </div>
      <input
        v-model="leftOne.entriesQuantity"
        type="text"
        class="question-input"
        placeholder="Впишите количесвто входов на объекте"
        @input="setChanges()"
      />
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="question-block" v-if="leftOne.securityType == 'secureButton'">
      <div class="question-text">
        Как вы хотите использовать тревожную кнопку?
        <span
          class="info"
          @click="
            text = GETTEXT[5];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid buttons-grid-no-grid">
        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityButtonType == 'phisical'
          }"
          @click="
            leftOne.securityButtonType = 'phisical';
            setChanges();
          "
        >
          ФИЗИЧЕСКОЕ ОБОРУДОВАНИЕ
        </div>
        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityButtonType == 'app'
          }"
          @click="
            leftOne.securityButtonType = 'app';
            setChanges();
          "
        >
          МОБИЛЬНОЕ ПРИЛОЖЕНИЕ
        </div>

        <div
          class="button"
          :class="{
            'active-select-button': leftOne.securityButtonType == 'both'
          }"
          @click="
            leftOne.securityButtonType = 'both';
            setChanges();
          "
        >
          ВСЕ ВЫШЕПЕРЕЧИСЛЕННОЕ
        </div>
      </div>
    </div>

    <!--//////////////////////////////////////////////////////////////////////////////////////////-->

    <div class="question-block" v-if="leftOne.securityType == 'secureComplex'">
      <div class="question-text">
        Сколько у Вас помещений с окнами?
        <span
          class="info"
          @click="
            text = GETTEXT[4];
            infoPopup = true;
          "
        ></span>
      </div>
      <div class="buttons-grid">
        <div
          class="button"
          @click="
            leftOne.windowsQuantity = 1;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.windowsQuantity == 1 }"
        >
          1
        </div>
        <div
          class="button"
          @click="
            leftOne.windowsQuantity = 2;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.windowsQuantity == 2 }"
        >
          2
        </div>
        <div
          class="button"
          @click="
            leftOne.windowsQuantity = 3;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.windowsQuantity == 3 }"
        >
          3
        </div>
        <div
          class="button"
          @click="
            leftOne.windowsQuantity = 4;
            setChanges();
          "
          :class="{ 'active-select-button': leftOne.windowsQuantity == 4 }"
        >
          4
        </div>
      </div>
      <input
        v-model="leftOne.windowsQuantity"
        type="text"
        class="question-input"
        placeholder="Впишите количесвто входов на объекте"
        @input="setChanges()"
      />
    </div>

    <secInfoPopup v-show="infoPopup" :text="text" @close="infoPopup = false" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import secInfoPopup from "@/components/secInfoPopup.vue";

export default {
  name: "sec-one-left",
  components: {
    secInfoPopup
  },
  data: function() {
    return {
      leftOne: {
        securityReaction: null,
        entriesQuantity: null,
        securityButtonType: null,
        securityType: null, // 'secureButton', 'secureSignal', 'secureComplex'
        windowsQuantity: null
      },
      infoPopup: false,
      text: null
    };
  },
  computed: {
    ...mapGetters(["GETONELEFTPERCENT", "GETTEXT"])
  },
  methods: {
    ...mapMutations(["SETONELEFTPERCENT", "SETDATA"]),
    setChanges() {
      let i = 0;

      this.leftOne.securityReaction != null ? (i += 25) : null;

      ///////////////////////////////////////////////
      if (this.leftOne.securityType == "secureButton") {
        i += 50;
        this.leftOne.securityButtonType != null ? (i += 25) : null;
      }

      if (this.leftOne.securityType == "secureSignal") {
        i += 50;
        this.leftOne.entriesQuantity != null ? (i += 25) : null;
      }

      if (this.leftOne.securityType == "secureComplex") {
        i += 25;
        this.leftOne.windowsQuantity != null ? (i += 25) : null;
        this.leftOne.entriesQuantity != null ? (i += 25) : null;
      }
      /////////////////////////////////////////////////

      console.log(i);

      this.SETONELEFTPERCENT(i);
      this.SETDATA({ name: "oneLeft", data: this.leftOne });
    }
  }
};
</script>

<style lang="scss">
#sec-one-left {
  .question-block {
    margin-bottom: 17px;
    .question-text {
      margin-bottom: 13px;
      width: 98%;
      margin-left: auto;
      margin-right: auto;

      .info {
        @include info;
      }
    }

    .buttons-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 15px;

      .button {
        @include button-main;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .buttons-grid-two {
      width: 98%;
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: auto;

      .button {
        margin-left: 0%;
        margin-right: 0;
      }

      .button-right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .buttons-grid-no-grid {
      display: block;

      .button {
        margin-top: 15px;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .question-input {
      @include input-main;
      width: 98%;
    }
  }
}
</style>
