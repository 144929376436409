var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sec-one-left"}},[_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Нужно ли Вам реагирование сотрудниками охраны на сработки датчиков? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[1];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid buttons-grid-two"},[_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.securityReaction == false },on:{"click":function($event){_vm.leftOne.securityReaction = false;
          _vm.setChanges();}}},[_vm._v(" НЕТ ")]),_c('div',{staticClass:"button button-right",class:{ 'active-select-button': _vm.leftOne.securityReaction == true },on:{"click":function($event){_vm.leftOne.securityReaction = true;
          _vm.setChanges();}}},[_vm._v(" ДА ")])])]),_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Какой комплект Вам нужен? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[2];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid buttons-grid-no-grid"},[_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityType == 'secureSignal'
        },on:{"click":function($event){_vm.leftOne.securityType = 'secureSignal';
          _vm.setChanges();}}},[_vm._v(" ОХРАННАЯ СИГНАЛИЗАЦИЯ ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityType == 'secureButton'
        },on:{"click":function($event){_vm.leftOne.securityType = 'secureButton';
          _vm.setChanges();}}},[_vm._v(" ТРЕВОЖНАЯ КНОПКА ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityType == 'secureComplex'
        },on:{"click":function($event){_vm.leftOne.securityType = 'secureComplex';
          _vm.setChanges();}}},[_vm._v(" КОМПЛЕКСНАЯ ОХРАНА ")])])]),(
      _vm.leftOne.securityType == 'secureSignal' ||
        _vm.leftOne.securityType == 'secureComplex'
    )?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Сколько у Вас входов на объект? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[3];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid"},[_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.entriesQuantity == 1 },on:{"click":function($event){_vm.leftOne.entriesQuantity = 1;
          _vm.setChanges();}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.entriesQuantity == 2 },on:{"click":function($event){_vm.leftOne.entriesQuantity = 2;
          _vm.setChanges();}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.entriesQuantity == 3 },on:{"click":function($event){_vm.leftOne.entriesQuantity = 3;
          _vm.setChanges();}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.entriesQuantity == 4 },on:{"click":function($event){_vm.leftOne.entriesQuantity = 4;
          _vm.setChanges();}}},[_vm._v(" 4 ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.leftOne.entriesQuantity),expression:"leftOne.entriesQuantity"}],staticClass:"question-input",attrs:{"type":"text","placeholder":"Впишите количесвто входов на объекте"},domProps:{"value":(_vm.leftOne.entriesQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.leftOne, "entriesQuantity", $event.target.value)},function($event){return _vm.setChanges()}]}})]):_vm._e(),(_vm.leftOne.securityType == 'secureButton')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Как вы хотите использовать тревожную кнопку? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[5];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid buttons-grid-no-grid"},[_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityButtonType == 'phisical'
        },on:{"click":function($event){_vm.leftOne.securityButtonType = 'phisical';
          _vm.setChanges();}}},[_vm._v(" ФИЗИЧЕСКОЕ ОБОРУДОВАНИЕ ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityButtonType == 'app'
        },on:{"click":function($event){_vm.leftOne.securityButtonType = 'app';
          _vm.setChanges();}}},[_vm._v(" МОБИЛЬНОЕ ПРИЛОЖЕНИЕ ")]),_c('div',{staticClass:"button",class:{
          'active-select-button': _vm.leftOne.securityButtonType == 'both'
        },on:{"click":function($event){_vm.leftOne.securityButtonType = 'both';
          _vm.setChanges();}}},[_vm._v(" ВСЕ ВЫШЕПЕРЕЧИСЛЕННОЕ ")])])]):_vm._e(),(_vm.leftOne.securityType == 'secureComplex')?_c('div',{staticClass:"question-block"},[_c('div',{staticClass:"question-text"},[_vm._v(" Сколько у Вас помещений с окнами? "),_c('span',{staticClass:"info",on:{"click":function($event){_vm.text = _vm.GETTEXT[4];
          _vm.infoPopup = true;}}})]),_c('div',{staticClass:"buttons-grid"},[_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.windowsQuantity == 1 },on:{"click":function($event){_vm.leftOne.windowsQuantity = 1;
          _vm.setChanges();}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.windowsQuantity == 2 },on:{"click":function($event){_vm.leftOne.windowsQuantity = 2;
          _vm.setChanges();}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.windowsQuantity == 3 },on:{"click":function($event){_vm.leftOne.windowsQuantity = 3;
          _vm.setChanges();}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"button",class:{ 'active-select-button': _vm.leftOne.windowsQuantity == 4 },on:{"click":function($event){_vm.leftOne.windowsQuantity = 4;
          _vm.setChanges();}}},[_vm._v(" 4 ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.leftOne.windowsQuantity),expression:"leftOne.windowsQuantity"}],staticClass:"question-input",attrs:{"type":"text","placeholder":"Впишите количесвто входов на объекте"},domProps:{"value":(_vm.leftOne.windowsQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.leftOne, "windowsQuantity", $event.target.value)},function($event){return _vm.setChanges()}]}})]):_vm._e(),_c('secInfoPopup',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoPopup),expression:"infoPopup"}],attrs:{"text":_vm.text},on:{"close":function($event){_vm.infoPopup = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }