<template>
  <div id="set-info-popup" @click="close()">
    <div class="info-popup-holder" @click.stop>
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "set-info-popup",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  destroyed() {
    document.body.style.overflowY = "auto";
  }
};
</script>

<style lang="scss">
#set-info-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;

  .info-popup-holder {
    width: 320px;
    padding: 10px;
    background-color: #fff;
  }
}
</style>
